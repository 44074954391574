/**
 * Documentation KFP API
 * ## Common Request Headers - Authorization (string `Bearer {AccessToken}`, if signed-in) - After the client has successfully obtained `AccessToken` via Login, it\'s sent in every request as a bearer token.
 *
 * The version of the OpenAPI document: 1
 * Contact: novak@techcrowd.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateFinMathRequest { 
    /**
     * Test
     */
    futureValue?: number;
    /**
     * Test
     */
    initialPayment?: number;
    /**
     * Test
     */
    interestFrequency: string;
    /**
     * Test
     */
    monthlyPayment?: number;
    /**
     * Test
     */
    rate?: number;
    /**
     * Test
     */
    yearlyDuration?: number;
    /**
     * Test
     */
    monthlyPaymentFlaged?: number;
    /**
     * Test
     */
    yearlyDurationFlaged?: number;
    /**
     * Test
     */
    futureValueFlaged?: number;
    /**
     * Test
     */
    initialPaymentFlaged?: number;
    /**
     * Test
     */
    rateFlaged?: number;
}

