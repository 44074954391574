/**
 * Documentation KFP API
 * ## Common Request Headers - Authorization (string `Bearer {AccessToken}`, if signed-in) - After the client has successfully obtained `AccessToken` via Login, it\'s sent in every request as a bearer token.
 *
 * The version of the OpenAPI document: 1
 * Contact: novak@techcrowd.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { UserResource } from './userResource';
import { CompanyLicenceResource } from './companyLicenceResource';


export interface CompanyResource { 
    /**
     * Company ID
     */
    id: number;
    /**
     * test
     */
    name: string;
    /**
     * test
     */
    street?: string;
    /**
     * test
     */
    city?: string;
    /**
     * test
     */
    color?: string;
    /**
     * test
     */
    colorSecondary?: string;
    /**
     * test
     */
    pdfImagePath?: string;
    /**
     * test
     */
    imagePath?: string;
    /**
     * test
     */
    zipCode?: string;
    /**
     * test
     */
    identificationNumber?: string;
    /**
     * test
     */
    vatNumber?: string;
    /**
     * test
     */
    trialStart?: string;
    /**
     * test
     */
    trialEnd?: string;
    /**
     * test
     */
    hasLicence?: boolean;
    /**
     * test
     */
    licenceCount?: number;
    /**
     * test
     */
    note?: string;
    /**
     * test
     */
    clientId?: string;
    /**
     * test
     */
    clientSecret?: string;
    /**
     * List users from company
     */
    users?: Array<UserResource>;
    /**
     * List licences from company
     */
    licences?: Array<CompanyLicenceResource>;
    createdAt?: string;
    updatedAt?: string;
}

