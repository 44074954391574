/**
 * Documentation KFP API
 * ## Common Request Headers - Authorization (string `Bearer {AccessToken}`, if signed-in) - After the client has successfully obtained `AccessToken` via Login, it\'s sent in every request as a bearer token.
 *
 * The version of the OpenAPI document: 1
 * Contact: novak@techcrowd.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface MonthlyCalculatedRecordDto { 
    /**
     * Test
     */
    portfolio: number;
    /**
     * Test
     */
    allocationShares: number;
    /**
     * Test
     */
    allocationFinMarket: number;
    /**
     * Test
     */
    allocationBonds: number;
    /**
     * Test
     */
    investmentValue: number;
    /**
     * Test
     */
    depositValue: number;
    /**
     * Test
     */
    investmentShares: number;
    /**
     * Test
     */
    investmentFinMarket: number;
    /**
     * Test
     */
    investmentBonds: number;
    /**
     * Test
     */
    investmentInflation: number;
    /**
     * Test
     */
    investmentPortfolio: number;
    /**
     * Test
     */
    date: string;
}

