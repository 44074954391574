/**
 * Documentation KFP API
 * ## Common Request Headers - Authorization (string `Bearer {AccessToken}`, if signed-in) - After the client has successfully obtained `AccessToken` via Login, it\'s sent in every request as a bearer token.
 *
 * The version of the OpenAPI document: 1
 * Contact: novak@techcrowd.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { AggregatedStatisticsContainerByHorizonDto } from '../model/models';
import { AggregatedStatisticsContainerDto } from '../model/models';
import { CreateFinProbabilityRequest } from '../model/models';
import { HorizonProbabilityContainerDto } from '../model/models';
import { ProbabilityStatisticsDto } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface FinProbabilityServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * Average valorization by horizon
     * 
     * @param createFinProbabilityRequest 
     */
    calculateAverageValorizationByHorizon(createFinProbabilityRequest?: CreateFinProbabilityRequest, extraHttpRequestParams?: any): Observable<AggregatedStatisticsContainerByHorizonDto>;

    /**
     * Average valorization probability
     * 
     * @param createFinProbabilityRequest 
     */
    calculateAverageValorizationProbability(createFinProbabilityRequest?: CreateFinProbabilityRequest, extraHttpRequestParams?: any): Observable<AggregatedStatisticsContainerDto>;

    /**
     * Base statistics
     * 
     * @param createFinProbabilityRequest 
     */
    calculateBaseStatistics(createFinProbabilityRequest?: CreateFinProbabilityRequest, extraHttpRequestParams?: any): Observable<ProbabilityStatisticsDto>;

    /**
     * Detailed statistics
     * 
     * @param createFinProbabilityRequest 
     */
    calculateDetailedStatistics(createFinProbabilityRequest?: CreateFinProbabilityRequest, extraHttpRequestParams?: any): Observable<ProbabilityStatisticsDto>;

    /**
     * Loss probability by horizon
     * 
     * @param createFinProbabilityRequest 
     */
    calculateLossProbabilityByHorizon(createFinProbabilityRequest?: CreateFinProbabilityRequest, extraHttpRequestParams?: any): Observable<HorizonProbabilityContainerDto>;

    /**
     * Optimistic loss by horizon
     * 
     * @param createFinProbabilityRequest 
     */
    calculateOptimisticLossByHorizon(createFinProbabilityRequest?: CreateFinProbabilityRequest, extraHttpRequestParams?: any): Observable<HorizonProbabilityContainerDto>;

    /**
     * Pessimistic loss by horizon
     * 
     * @param createFinProbabilityRequest 
     */
    calculatePessimisticLossByHorizon(createFinProbabilityRequest?: CreateFinProbabilityRequest, extraHttpRequestParams?: any): Observable<HorizonProbabilityContainerDto>;

    /**
     * Realistic loss by horizon
     * 
     * @param createFinProbabilityRequest 
     */
    calculateRealisticLossByHorizon(createFinProbabilityRequest?: CreateFinProbabilityRequest, extraHttpRequestParams?: any): Observable<HorizonProbabilityContainerDto>;

    /**
     * Total profits probability
     * 
     * @param createFinProbabilityRequest 
     */
    calculateTotalProfitsProbability(createFinProbabilityRequest?: CreateFinProbabilityRequest, extraHttpRequestParams?: any): Observable<AggregatedStatisticsContainerDto>;

    /**
     * Total values probability
     * 
     * @param createFinProbabilityRequest 
     */
    calculateTotalValuesProbability(createFinProbabilityRequest?: CreateFinProbabilityRequest, extraHttpRequestParams?: any): Observable<AggregatedStatisticsContainerDto>;

}
