/**
 * Documentation KFP API
 * ## Common Request Headers - Authorization (string `Bearer {AccessToken}`, if signed-in) - After the client has successfully obtained `AccessToken` via Login, it\'s sent in every request as a bearer token.
 *
 * The version of the OpenAPI document: 1
 * Contact: novak@techcrowd.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { CreateFinancialRequest } from '../model/models';
import { FinancialDataDto } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface FinMortgageInvestmentServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * Financial multiple data
     * 
     * @param createFinancialRequest 
     */
    getMultipleFinancialData(createFinancialRequest?: CreateFinancialRequest, extraHttpRequestParams?: any): Observable<Array<FinancialDataDto>>;

}
