/**
 * Documentation KFP API
 * ## Common Request Headers - Authorization (string `Bearer {AccessToken}`, if signed-in) - After the client has successfully obtained `AccessToken` via Login, it\'s sent in every request as a bearer token.
 *
 * The version of the OpenAPI document: 1
 * Contact: novak@techcrowd.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { LoginResource } from '../model/models';
import { PasswordNewRequest } from '../model/models';
import { PasswordResetRequest } from '../model/models';
import { ResetPasswordRequest } from '../model/models';
import { ResetUserPasswordRequest } from '../model/models';
import { SendResetLink200ResponseInner } from '../model/models';
import { SendResetLink500ResponseInner } from '../model/models';
import { SendResetLinkRequest } from '../model/models';
import { UserResource } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface AuthorizationPasswordServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * Check reset password token
     * 
     * @param token 
     * @param email User email
     */
    check(token: string, email: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * Create new password for user
     * 
     * @param passwordNewRequest 
     */
    createPassword(passwordNewRequest?: PasswordNewRequest, extraHttpRequestParams?: any): Observable<LoginResource>;

    /**
     * Redirect to url for create new password
     * 
     * @param email User email
     */
    newPassword(email: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * Change password for user
     * 
     * @param passwordResetRequest 
     */
    reset(passwordResetRequest?: PasswordResetRequest, extraHttpRequestParams?: any): Observable<LoginResource>;

    /**
     * Reset password for any user - only administrator
     * 
     * @param resetPasswordRequest 
     */
    resetPassword(resetPasswordRequest?: ResetPasswordRequest, extraHttpRequestParams?: any): Observable<UserResource>;

    /**
     * Reset password for user in administration
     * 
     * @param resetUserPasswordRequest 
     */
    resetUserPassword(resetUserPasswordRequest?: ResetUserPasswordRequest, extraHttpRequestParams?: any): Observable<UserResource>;

    /**
     * Send reset password link
     * 
     * @param sendResetLinkRequest 
     */
    sendResetLink(sendResetLinkRequest?: SendResetLinkRequest, extraHttpRequestParams?: any): Observable<Array<SendResetLink200ResponseInner>>;

}
