/**
 * Documentation KFP API
 * ## Common Request Headers - Authorization (string `Bearer {AccessToken}`, if signed-in) - After the client has successfully obtained `AccessToken` via Login, it\'s sent in every request as a bearer token.
 *
 * The version of the OpenAPI document: 1
 * Contact: novak@techcrowd.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CashFlowTimeDTO } from './cashFlowTimeDTO';
import { InvestmentDTO } from './investmentDTO';


export interface RealEstateDataDto { 
    /**
     * Test
     */
    initialCashFlowResult: number;
    cashFlowByTimeResults: Array<CashFlowTimeDTO>;
    investmentByTimeResults: Array<InvestmentDTO>;
    /**
     * Test
     */
    sumOneTimeCosts: number;
    /**
     * Test
     */
    mortgageResult: number;
}

