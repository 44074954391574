/**
 * Documentation KFP API
 * ## Common Request Headers - Authorization (string `Bearer {AccessToken}`, if signed-in) - After the client has successfully obtained `AccessToken` via Login, it\'s sent in every request as a bearer token.
 *
 * The version of the OpenAPI document: 1
 * Contact: novak@techcrowd.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { FeeDto } from './feeDto';


export interface CreateHistoryDataRequest { 
    /**
     * Test
     */
    startMonth: number;
    /**
     * Test
     */
    startYear: number;
    /**
     * Test
     */
    yieldType: string;
    /**
     * Test
     */
    duration: number;
    /**
     * Test
     */
    initialInvestment: number;
    /**
     * Test
     */
    monthlyInvestment: number;
    /**
     * test
     */
    investmentFee?: number;
    /**
     * Test
     */
    sharesAllocation: Array<number>;
    /**
     * Test
     */
    bondsAllocation: Array<number>;
    /**
     * Test
     */
    finMarketAllocation: Array<number>;
    profitFees?: FeeDto;
    allocationFees?: FeeDto;
    /**
     * Test
     */
    bonds?: number;
    /**
     * Test
     */
    shares?: number;
    /**
     * Test
     */
    finMarket?: number;
    /**
     * Test
     */
    showFees?: boolean;
}

