import { NgModule, ModuleWithProviders, SkipSelf, Optional } from '@angular/core';
import { Configuration } from './configuration';
import { HttpClient } from '@angular/common/http';

import { AuthorizationService } from './api/authorization.service';
import { AuthorizationPasswordService } from './api/authorizationPassword.service';
import { CalculationService } from './api/calculation.service';
import { CompanyService } from './api/company.service';
import { CompanyLicenceService } from './api/companyLicence.service';
import { ExportService } from './api/export.service';
import { FinHistoryService } from './api/finHistory.service';
import { FinMathService } from './api/finMath.service';
import { FinMortgageInvestmentService } from './api/finMortgageInvestment.service';
import { FinProbabilityService } from './api/finProbability.service';
import { FinRealEstateService } from './api/finRealEstate.service';
import { FlagService } from './api/flag.service';
import { ImportService } from './api/import.service';
import { LanguageService } from './api/language.service';
import { LogService } from './api/log.service';
import { RoleService } from './api/role.service';
import { StripeService } from './api/stripe.service';
import { TypeService } from './api/type.service';
import { UserService } from './api/user.service';
import { VideoService } from './api/video.service';

@NgModule({
  imports:      [],
  declarations: [],
  exports:      [],
  providers: []
})
export class ApiModule {
    public static forRoot(configurationFactory: () => Configuration): ModuleWithProviders<ApiModule> {
        return {
            ngModule: ApiModule,
            providers: [ { provide: Configuration, useFactory: configurationFactory } ]
        };
    }

    constructor( @Optional() @SkipSelf() parentModule: ApiModule,
                 @Optional() http: HttpClient) {
        if (parentModule) {
            throw new Error('ApiModule is already loaded. Import in your base AppModule only.');
        }
        if (!http) {
            throw new Error('You need to import the HttpClientModule in your AppModule! \n' +
            'See also https://github.com/angular/angular/issues/20575');
        }
    }
}
