/**
 * Documentation KFP API
 * ## Common Request Headers - Authorization (string `Bearer {AccessToken}`, if signed-in) - After the client has successfully obtained `AccessToken` via Login, it\'s sent in every request as a bearer token.
 *
 * The version of the OpenAPI document: 1
 * Contact: novak@techcrowd.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { BaseFilter } from '../model/models';
import { CompanyResource } from '../model/models';
import { CreateCompanyAdminRequest } from '../model/models';
import { CreateCompanyRequest } from '../model/models';
import { CreateRemoteAuthorizationRequest } from '../model/models';
import { ErrorDto } from '../model/models';
import { GenerateCompanyTokenRequest } from '../model/models';
import { GetAccessTokenForUserRemoteAuthorizationRequest } from '../model/models';
import { GetAllCompanies200ResponseInner } from '../model/models';
import { GetUsersFromCompany200ResponseInner } from '../model/models';
import { LoginResource } from '../model/models';
import { RemoteAuthorizationByClient200Response } from '../model/models';
import { UpdateCompanyColorRequest } from '../model/models';
import { UpdateCompanyRequest } from '../model/models';
import { UserResource } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface CompanyServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * Saves new company to database
     * 
     * @param createCompanyRequest 
     */
    createNewCompany(createCompanyRequest?: CreateCompanyRequest, extraHttpRequestParams?: any): Observable<CompanyResource>;

    /**
     * Create new user account with company
     * 
     * @param createCompanyAdminRequest 
     */
    createUserAccountWithCompany(createCompanyAdminRequest?: CreateCompanyAdminRequest, extraHttpRequestParams?: any): Observable<UserResource>;

    /**
     * Deletes company with given by ID
     * 
     * @param id 
     */
    deleteCompany(id: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * Delete user from company by ID
     * 
     * @param id 
     */
    deleteUserFromCompany(id: string, extraHttpRequestParams?: any): Observable<UserResource>;

    /**
     * Generate clientID and clientSecret for company by given ID
     * 
     * @param id 
     */
    generateCompanyInfo(id: string, extraHttpRequestParams?: any): Observable<CompanyResource>;

    /**
     * Generate clientID and clientSecret for company
     * 
     * @param generateCompanyTokenRequest 
     */
    generateCompanyToken(generateCompanyTokenRequest?: GenerateCompanyTokenRequest, extraHttpRequestParams?: any): Observable<LoginResource>;

    /**
     * Get access token for user remote authorization
     * 
     * @param getAccessTokenForUserRemoteAuthorizationRequest 
     */
    getAccessTokenForUserRemoteAuthorization(getAccessTokenForUserRemoteAuthorizationRequest?: GetAccessTokenForUserRemoteAuthorizationRequest, extraHttpRequestParams?: any): Observable<LoginResource>;

    /**
     * Get all companies
     * 
     * @param filter Get companies by filter
     */
    getAllCompanies(filter?: BaseFilter, extraHttpRequestParams?: any): Observable<Array<GetAllCompanies200ResponseInner>>;

    /**
     * Find company by given ID
     * 
     * @param id 
     */
    getCompanyById(id: string, extraHttpRequestParams?: any): Observable<CompanyResource>;

    /**
     * Get users from company
     * 
     * @param id 
     * @param filter Get users by filter
     */
    getUsersFromCompany(id: string, filter?: BaseFilter, extraHttpRequestParams?: any): Observable<Array<GetUsersFromCompany200ResponseInner>>;

    /**
     * Remote authorization by client bearer token
     * 
     * @param createRemoteAuthorizationRequest 
     */
    remoteAuthorizationByClient(createRemoteAuthorizationRequest?: CreateRemoteAuthorizationRequest, extraHttpRequestParams?: any): Observable<RemoteAuthorizationByClient200Response>;

    /**
     * Updates an existing company
     * 
     * @param id 
     * @param updateCompanyRequest 
     */
    updateCompany(id: string, updateCompanyRequest?: UpdateCompanyRequest, extraHttpRequestParams?: any): Observable<CompanyResource>;

    /**
     * Update company color
     * 
     * @param id 
     * @param updateCompanyColorRequest 
     */
    updateCompanyColor(id: string, updateCompanyColorRequest?: UpdateCompanyColorRequest, extraHttpRequestParams?: any): Observable<CompanyResource>;

    /**
     * Update company logo
     * 
     * @param id 
     * @param file Uploaded file
     */
    updateCompanyLogo(id: string, file: Blob, extraHttpRequestParams?: any): Observable<CompanyResource>;

    /**
     * Update company pdf image
     * 
     * @param id 
     * @param file Uploaded file
     */
    updateCompanyPdfImage(id: string, file: Blob, extraHttpRequestParams?: any): Observable<CompanyResource>;

}
