/**
 * Documentation KFP API
 * ## Common Request Headers - Authorization (string `Bearer {AccessToken}`, if signed-in) - After the client has successfully obtained `AccessToken` via Login, it\'s sent in every request as a bearer token.
 *
 * The version of the OpenAPI document: 1
 * Contact: novak@techcrowd.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { BaseFilter } from '../model/models';
import { CreateUserRequest } from '../model/models';
import { ErrorDto } from '../model/models';
import { GetUsersFromCompany200ResponseInner } from '../model/models';
import { ResendEmailRequest } from '../model/models';
import { UpdateUserBrandingValueRequest } from '../model/models';
import { UpdateUserColorRequest } from '../model/models';
import { UpdateUserRemoteRequest } from '../model/models';
import { UpdateUserRequest } from '../model/models';
import { UpdateUserTrialEndsAtRequest } from '../model/models';
import { UserResource } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface UserServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * Create new user
     * 
     * @param createUserRequest 
     */
    createNewUser(createUserRequest?: CreateUserRequest, extraHttpRequestParams?: any): Observable<UserResource>;

    /**
     * Delete avatar from user
     * 
     * @param id 
     */
    deleteAvatarFromUser(id: string, extraHttpRequestParams?: any): Observable<UserResource>;

    /**
     * Delete logo from user
     * 
     * @param id 
     */
    deleteLogoFromUser(id: string, extraHttpRequestParams?: any): Observable<UserResource>;

    /**
     * Delete user by ID
     * 
     * @param id 
     */
    deleteUserById(id: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * Delete user by ID for remote authorize
     * 
     * @param id 
     */
    deleteUserByIdRemoteAuthorize(id: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * Get user by ID
     * 
     * @param id 
     */
    getUserById(id: string, extraHttpRequestParams?: any): Observable<UserResource>;

    /**
     * Get user PDF info by ID
     * 
     * @param id 
     */
    getUserPdfInfoById(id: string, extraHttpRequestParams?: any): Observable<UserResource>;

    /**
     * Get user list
     * 
     * @param filter Get users by filter
     */
    getUsers(filter?: BaseFilter, extraHttpRequestParams?: any): Observable<Array<GetUsersFromCompany200ResponseInner>>;

    /**
     * Get user list for remote authorize
     * 
     * @param filter Get users by filter
     */
    getUsersListRemoteAuthorize(filter?: BaseFilter, extraHttpRequestParams?: any): Observable<Array<GetUsersFromCompany200ResponseInner>>;

    /**
     * Impersonate user by ID
     * 
     * @param id 
     */
    impersonate(id: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * Resend verification email to user
     * 
     * @param resendEmailRequest 
     */
    resendEmail(resendEmailRequest?: ResendEmailRequest, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * Update user branding value
     * 
     * @param id 
     * @param updateUserBrandingValueRequest 
     */
    updateUserBrandingValue(id: string, updateUserBrandingValueRequest?: UpdateUserBrandingValueRequest, extraHttpRequestParams?: any): Observable<UserResource>;

    /**
     * Update user by ID
     * 
     * @param id 
     * @param updateUserRequest 
     */
    updateUserById(id: string, updateUserRequest?: UpdateUserRequest, extraHttpRequestParams?: any): Observable<UserResource>;

    /**
     * Update user by ID for remote authorize
     * 
     * @param id 
     * @param updateUserRemoteRequest 
     */
    updateUserByIdRemoteAuthorize(id: string, updateUserRemoteRequest?: UpdateUserRemoteRequest, extraHttpRequestParams?: any): Observable<UserResource>;

    /**
     * Update user colors
     * 
     * @param id 
     * @param updateUserColorRequest 
     */
    updateUserColor(id: string, updateUserColorRequest?: UpdateUserColorRequest, extraHttpRequestParams?: any): Observable<UserResource>;

    /**
     * Update user logo
     * 
     * @param id 
     * @param file Uploaded file
     */
    updateUserLogo(id: string, file: Blob, extraHttpRequestParams?: any): Observable<UserResource>;

    /**
     * Update user pdf image
     * 
     * @param id 
     * @param file Uploaded file
     */
    updateUserPdfImage(id: string, file: Blob, extraHttpRequestParams?: any): Observable<UserResource>;

    /**
     * Update user trial ends at
     * 
     * @param id 
     * @param updateUserTrialEndsAtRequest 
     */
    updateUserTrialEndsAt(id: string, updateUserTrialEndsAtRequest?: UpdateUserTrialEndsAtRequest, extraHttpRequestParams?: any): Observable<UserResource>;

    /**
     * Upload avatar to user
     * 
     * @param id 
     * @param file Uploaded file
     */
    uploadAvatarToUser(id: string, file: Blob, extraHttpRequestParams?: any): Observable<UserResource>;

    /**
     * User email verification
     * 
     * @param id 
     */
    verificationEmail(id: string, extraHttpRequestParams?: any): Observable<{}>;

}
