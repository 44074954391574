/**
 * Documentation KFP API
 * ## Common Request Headers - Authorization (string `Bearer {AccessToken}`, if signed-in) - After the client has successfully obtained `AccessToken` via Login, it\'s sent in every request as a bearer token.
 *
 * The version of the OpenAPI document: 1
 * Contact: novak@techcrowd.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { CalculatedFinMathContainer } from '../model/models';
import { CreateFinMathRequest } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface FinMathServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * Math investment
     * 
     * @param createFinMathRequest 
     */
    calculateInvestment(createFinMathRequest?: CreateFinMathRequest, extraHttpRequestParams?: any): Observable<CalculatedFinMathContainer>;

    /**
     * Math mortgage
     * 
     * @param createFinMathRequest 
     */
    calculateMortgage(createFinMathRequest?: CreateFinMathRequest, extraHttpRequestParams?: any): Observable<CalculatedFinMathContainer>;

    /**
     * Math rent
     * 
     * @param createFinMathRequest 
     */
    calculateRent(createFinMathRequest?: CreateFinMathRequest, extraHttpRequestParams?: any): Observable<CalculatedFinMathContainer>;

}
