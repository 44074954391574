/**
 * Documentation KFP API
 * ## Common Request Headers - Authorization (string `Bearer {AccessToken}`, if signed-in) - After the client has successfully obtained `AccessToken` via Login, it\'s sent in every request as a bearer token.
 *
 * The version of the OpenAPI document: 1
 * Contact: novak@techcrowd.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { CreateUserRequestAddress } from './createUserRequestAddress';


export interface CreateUserRequest { 
    /**
     * First name
     */
    firstname: string;
    /**
     * Last name
     */
    lastname: string;
    /**
     * Email
     */
    email: string;
    /**
     * Phone
     */
    phone?: string;
    /**
     * password
     */
    password?: string;
    /**
     * currency
     */
    currency?: string;
    /**
     * position
     */
    position?: string;
    /**
     * personalClaim
     */
    personalClaim?: string;
    /**
     * street
     */
    street?: string;
    /**
     * vatNumber
     */
    vatNumber?: string;
    /**
     * idNumber
     */
    identificationNumber?: string;
    /**
     * zipCode
     */
    zipCode?: string;
    /**
     * language
     */
    language?: string;
    /**
     * addressStreet
     */
    addressStreet?: string;
    /**
     * addressCity
     */
    addressCity?: string;
    /**
     * addressZip
     */
    addressZip?: string;
    /**
     * name
     */
    name?: string;
    /**
     * Confirmation password
     */
    passwordConfirmation?: string;
    /**
     * Birth date
     */
    birthDate?: string;
    address?: CreateUserRequestAddress;
    /**
     * gender
     */
    gender?: string;
    /**
     * companyId
     */
    companyId?: number;
}

