import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { BehaviorSubject, combineLatest, map, Observable, switchMap } from 'rxjs';
import {
    ApexAxisChartSeries,
    ApexChart,
    ApexDataLabels,
    ApexFill,
    ApexLegend,
    ApexMarkers,
    ApexPlotOptions,
    ApexResponsive,
    ApexStroke,
    ApexTitleSubtitle,
    ApexTooltip,
    ApexXAxis,
    ApexYAxis,
} from 'ng-apexcharts';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CreateFinProbabilityRequest } from 'kfp';
import { ProbabilityService } from './probability.service';
import { ProbabilityBasicChart } from '../charts/probability-basis-chart';
import { ProbabilityChart } from '../charts/probability-chart';
import { ProbabilityProfitChart } from '../charts/probability-profit-chart';
import { ProbabilityHistoryChart } from '../charts/probability-history-chart';

export type ProbabilityChartType = 'BASIS' | 'PROBABILITY' | 'HISTORY' | 'PROFIT' | 'DETAIL';
export type ProbabilityChartOptions = {
    params?: any;
};

export type ChartOptions = {
    series: ApexAxisChartSeries;
    chart: ApexChart;
    xaxis?: ApexXAxis;
    stroke?: ApexStroke;
    dataLabels?: ApexDataLabels;
    yaxis?: ApexYAxis;
    title?: ApexTitleSubtitle;
    labels?: string[];
    legend?: ApexLegend;
    subtitle?: ApexTitleSubtitle;
    plotOptions?: ApexPlotOptions;
    colors: string[];
    tooltip: ApexTooltip;
    fill?: ApexFill;
    markers?: ApexMarkers;
    responsive?: ApexResponsive;
};

@UntilDestroy()
@Injectable({
    providedIn: 'root',
})
export class ProbabilityChartService {
    public locale = 'cs';

    constructor(
        public translateService: TranslateService,
        private _httpClient: HttpClient,
        private _probabilityService: ProbabilityService,
        private readonly _basisChart: ProbabilityBasicChart,
        private readonly _probabilityChart: ProbabilityChart,
        private readonly _probabilityProfitChart: ProbabilityProfitChart,
        private readonly _probabilityHistoryChart: ProbabilityHistoryChart
    ) {}

    public basisChart(parameters: CreateFinProbabilityRequest): Observable<ChartOptions> {
        return this._probabilityService.dataBaseStatistics(parameters).pipe(
            untilDestroyed(this),
            switchMap((res) => {
                this._basisChart.updateSeries(res);
                return this._basisChart.getChartData();
            })
        );
    }

    public probabilityChart(parameters: CreateFinProbabilityRequest): Observable<ChartOptions> {
        return this._probabilityService.dataAverageValorizationProbability(parameters).pipe(
            untilDestroyed(this),
            switchMap((res) => {
                this._probabilityChart.updateSeries(res);
                return this._probabilityChart.getChartData();
            })
        );
    }

    public historyChart(parameters: CreateFinProbabilityRequest): Observable<ChartOptions[]> {
        return this._probabilityService.dataHistoryStatistics(parameters).pipe(
            untilDestroyed(this),
            switchMap((res) => {
                this._probabilityHistoryChart.updateSeries(res);
                return this._probabilityHistoryChart.getChartsData();
            })
        );
    }

    public profitChart(parameters: CreateFinProbabilityRequest): Observable<ChartOptions> {
        return this._probabilityService.dataProbabilityPessimisticLossByHorizon(parameters).pipe(
            untilDestroyed(this),
            switchMap((res) => {
                this._probabilityProfitChart.updateSeries(res);
                return this._probabilityProfitChart.getChartData();
            })
        );
    }

    public chartByType(type: ProbabilityChartType, parameters: CreateFinProbabilityRequest, options?: ProbabilityChartOptions) {
        switch (type) {
            case 'BASIS':
                return this.basisChart(parameters);
            case 'PROBABILITY':
                return this.probabilityChart(parameters);
            case 'HISTORY':
                return this.historyChart(parameters);
            case 'PROFIT':
                return this.profitChart(parameters);
        }
    }

    prepareDataForPdfExport(parameters: CreateFinProbabilityRequest): Observable<{
        basis: ChartOptions;
        probability: ChartOptions;
        profit: ChartOptions;
        history: ChartOptions[];
        allocation: ChartOptions;
    }> {
        return combineLatest([
            this.basisChart(parameters),
            this.probabilityChart(parameters),
            this.profitChart(parameters),
            this.historyChart(parameters),
            this.allocationChart(parameters),
        ]).pipe(
            map(([basis, probability, profit, history, allocation]) => ({
                basis: basis,
                probability: probability,
                profit: profit,
                history: history,
                allocation: allocation,
            }))
        );
    }

    public allocationChart(parameters: CreateFinProbabilityRequest, mode: string = 'dynamic'): Observable<ChartOptions> {
        const data: number[] = [
            parameters.shares ? parameters.shares : 0,
            parameters.bonds ? parameters.bonds : 0,
            parameters.finMarket ? parameters.finMarket : 0,
        ];

        return this.translateService
            .stream([
                'probability.calculation.chart.stock',
                'probability.calculation.chart.bonds',
                'probability.calculation.chart.money_market',
                'probability.calculation.chart.dynamic',
                'probability.calculation.chart.balanced',
                'probability.calculation.chart.conservative',
                'probability.calculation.chart.custom',
            ])
            .pipe(
                switchMap((keys) => {
                    let modeTitle = '';
                    switch (mode) {
                        case 'dynamic':
                            modeTitle = keys['probability.calculation.chart.dynamic'];
                            break;
                        case 'balanced':
                            modeTitle = keys['probability.calculation.chart.balanced'];
                            break;
                        case 'conservative':
                            modeTitle = keys['probability.calculation.chart.conservative'];
                            break;
                        case 'custom':
                            modeTitle = keys['probability.calculation.chart.custom'];
                            break;
                    }
                    // @ts-ignore
                    const chartOptions = {
                        series: data,
                        labels: [
                            keys['probability.calculation.chart.stock'],
                            keys['probability.calculation.chart.bonds'],
                            keys['probability.calculation.chart.money_market'],
                        ],
                        chart: {
                            type: 'donut',
                            offsetY: 20,
                        },
                        colors: ['#84DACA', '#1078BB', '#8E6DEE'],
                        plotOptions: {
                            pie: {
                                expandOnClick: false,
                                donut: {
                                    size: '85%',
                                    labels: {
                                        show: true,
                                        name: {
                                            show: true,
                                            fontSize: '20px',
                                            fontWeight: 800,
                                            color: '#002B4F',
                                            offsetY: 0,
                                            formatter: () => modeTitle,
                                        },
                                        value: {
                                            show: true,
                                            fontSize: '14px',
                                            fontWeight: 600,
                                            color: '#8E8E93',
                                            offsetY: 5,
                                            formatter: () => '',
                                        },
                                        total: {
                                            show: true,
                                            showAlways: true,
                                            fontSize: '20px',
                                            fontWeight: 800,
                                            color: '#002B4F',
                                            offsetY: 0,
                                            formatter: () => '',
                                        },
                                    },
                                },
                            },
                        },
                        legend: {
                            show: false,
                        },
                        tooltip: {
                            enabled: false,
                        },
                        dataLabels: {
                            enabled: false,
                        },
                    } as ChartOptions;

                    return new BehaviorSubject<ChartOptions>(chartOptions).asObservable();
                })
            );
    }
}
