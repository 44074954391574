/**
 * Documentation KFP API
 * ## Common Request Headers - Authorization (string `Bearer {AccessToken}`, if signed-in) - After the client has successfully obtained `AccessToken` via Login, it\'s sent in every request as a bearer token.
 *
 * The version of the OpenAPI document: 1
 * Contact: novak@techcrowd.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { UpdateVideoRequest } from '../model/models';
import { VideoResource } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface VideoServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * Deletes video with given by ID
     * 
     * @param id 
     */
    deleteVideo(id: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * Get all videos
     * 
     */
    getAllVideos(extraHttpRequestParams?: any): Observable<{}>;

    /**
     * Find video by given ID
     * 
     * @param id 
     */
    getVideoById(id: string, extraHttpRequestParams?: any): Observable<VideoResource>;

    /**
     * Updates an existing video
     * 
     * @param id 
     * @param updateVideoRequest 
     */
    updateVideo(id: string, updateVideoRequest?: UpdateVideoRequest, extraHttpRequestParams?: any): Observable<VideoResource>;

}
