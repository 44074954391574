import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { HttpClient } from '@angular/common/http';
import { ApexAxisChartSeries } from 'ng-apexcharts';
import { combineLatest, Observable, of, switchMap } from 'rxjs';
import { formatNumber } from '@angular/common';
import { AggregatedStatisticsContainerByHorizonDto, CreateFinProbabilityRequest, FinProbabilityService } from 'kfp';

export type SeriesLabel = {
    series: ApexAxisChartSeries;
    labels: string[];
    min?: number;
    max?: number;
    colors?: string[];
};

@Injectable({
    providedIn: 'root',
})
export class ProbabilityService {
    constructor(
        public translateService: TranslateService,
        private _httpClient: HttpClient,
        private _probabilityService: FinProbabilityService
    ) {}

    dataProbabilityPessimisticLossByHorizon(dataRequest: CreateFinProbabilityRequest): Observable<SeriesLabel> {
        return combineLatest([
            this._probabilityService.calculatePessimisticLossByHorizon(dataRequest),
            this._probabilityService.calculateRealisticLossByHorizon(dataRequest),
            this._probabilityService.calculateOptimisticLossByHorizon(dataRequest),
        ]).pipe(
            switchMap(([dataPessimistic, dataRealistic, dataOptimistic]) => {
                const labels: string[] = [];
                let min = 0;
                let max = 0;

                const series: ApexAxisChartSeries = [
                    {
                        name: this.translateService.instant('probability.calculation.chart.pessimist'),
                        data: dataPessimistic.items.map((res) => {
                            const xValue = res.horizon.toString();
                            if (!labels.includes(xValue)) {
                                labels.push(xValue);
                            }
                            min = res.value < min ? res.value : min;
                            max = res.value > max ? res.value : max;

                            return { x: xValue, y: res.value };
                        }),
                    },
                    {
                        name: this.translateService.instant('probability.calculation.chart.realistic'),
                        data: dataRealistic.items.map((res) => {
                            const xValue = res.horizon.toString();
                            if (!labels.includes(xValue)) {
                                labels.push(xValue);
                            }

                            min = res.value < min ? res.value : min;
                            max = res.value > max ? res.value : max;

                            return { x: xValue, y: res.value };
                        }),
                    },
                    {
                        name: this.translateService.instant('probability.calculation.chart.optimistic'),
                        data: dataOptimistic.items.map((res) => {
                            const xValue = res.horizon.toString();
                            if (!labels.includes(xValue)) {
                                labels.push(xValue);
                            }
                            min = res.value < min ? res.value : min;
                            max = res.value > max ? res.value : max;

                            return { x: xValue, y: res.value };
                        }),
                    },
                ];

                return of({ series: series, labels: labels, min: min, max: max } as SeriesLabel);
            })
        );
    }

    dataAverageValorizationProbability(dataRequest: CreateFinProbabilityRequest): Observable<SeriesLabel> {
        return this._probabilityService.calculateAverageValorizationProbability(dataRequest).pipe(
            switchMap((data) => {
                const colors: string[] = [];
                const series: ApexAxisChartSeries = [
                    {
                        name: this.translateService.instant('probability.calculation.chart.average_profit_throughout'),
                        data: data.items?.map((res) => {
                            const xValueMin = formatNumber((res.min ? res.min : 0) * 100, 'cs', '1.0-0') + ' %';
                            const xValueMax = formatNumber((res.max ? res.max : 0) * 100, 'cs', '1.0-0') + ' %';
                            const color = (res.max ? res.max : 0) * 100 > 0 || res.max === null ? '#6BD3BF' : '#E43949';
                            colors.push(color);
                            return {
                                x:
                                    (res.max !== -0.1 ? xValueMin : '') +
                                    (res.max !== -0.1 && res.max !== null
                                        ? ' ' + this.translateService.instant('probability.calculation.chart.until') + ' '
                                        : '') +
                                    (res.max != null ? xValueMax : ''),
                                y: res.percentage * 100,
                            };
                        }),
                    },
                ];

                const labels: string[] = [];
                return of({ series: series, labels: labels, colors: colors } as SeriesLabel);
            })
        );
    }

    dataBaseStatistics(dataRequest: CreateFinProbabilityRequest): Observable<SeriesLabel> {
        return this._probabilityService.calculateBaseStatistics(dataRequest).pipe(
            switchMap((data) => {
                const series: ApexAxisChartSeries = [
                    {
                        name: this.translateService.instant('probability.calculation.chart.inserted_amount'),
                        data: [
                            data.detailedStatisticsList[0].totalInvestment,
                            data.detailedStatisticsList[2].totalInvestment,
                            data.detailedStatisticsList[1].totalInvestment,
                        ],
                        // @ts-ignore
                        labels: [
                            {
                                a: data.detailedStatisticsList[0].propertyValueTotal,
                                b: data.detailedStatisticsList[0].averageValorization,
                            },
                            {
                                a: data.detailedStatisticsList[1].propertyValueTotal,
                                b: data.detailedStatisticsList[1].averageValorization,
                            },
                            {
                                a: data.detailedStatisticsList[2].propertyValueTotal,
                                b: data.detailedStatisticsList[2].averageValorization,
                            },
                        ],
                    },
                    {
                        name: this.translateService.instant('probability.calculation.chart.profit'),
                        data: [
                            data.detailedStatisticsList[0].totalProfit,
                            data.detailedStatisticsList[1].totalProfit,
                            data.detailedStatisticsList[2].totalProfit,
                        ],
                        // @ts-ignore
                        labels: [
                            {
                                a: data.detailedStatisticsList[0].propertyValueTotal,
                                b: data.detailedStatisticsList[0].averageValorization,
                            },
                            {
                                a: data.detailedStatisticsList[1].propertyValueTotal,
                                b: data.detailedStatisticsList[1].averageValorization,
                            },
                            {
                                a: data.detailedStatisticsList[2].propertyValueTotal,
                                b: data.detailedStatisticsList[2].averageValorization,
                            },
                        ],
                    },
                ];
                const labels: string[] = [];

                return of({ series: series, labels: labels } as SeriesLabel);
            })
        );
    }

    dataHistoryStatistics(dataRequest: CreateFinProbabilityRequest): Observable<AggregatedStatisticsContainerByHorizonDto> {
        return this._probabilityService.calculateAverageValorizationByHorizon(dataRequest);
    }

    dataDetailStatistics(dataRequest: CreateFinProbabilityRequest) {
        return this._probabilityService.calculateDetailedStatistics(dataRequest).pipe();
    }
}
