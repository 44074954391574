/**
 * Documentation KFP API
 * ## Common Request Headers - Authorization (string `Bearer {AccessToken}`, if signed-in) - After the client has successfully obtained `AccessToken` via Login, it\'s sent in every request as a bearer token.
 *
 * The version of the OpenAPI document: 1
 * Contact: novak@techcrowd.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { AddUsersToFlagRequest } from '../model/models';
import { FlagResource } from '../model/models';
import { UpdateFlagRequest } from '../model/models';
import { UpdateUserFlagValuesRequest } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface FlagServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * Add users to flag
     * 
     * @param id 
     * @param addUsersToFlagRequest 
     */
    addUsersToFlag(id: string, addUsersToFlagRequest?: AddUsersToFlagRequest, extraHttpRequestParams?: any): Observable<FlagResource>;

    /**
     * Get flags
     * 
     */
    getFlagLists(extraHttpRequestParams?: any): Observable<Array<FlagResource>>;

    /**
     * Remove user from flag
     * 
     * @param flagId 
     * @param userId 
     */
    removeUserFromFlag(flagId: string, userId: string, extraHttpRequestParams?: any): Observable<FlagResource>;

    /**
     * Updates an existing flag
     * 
     * @param id 
     * @param updateFlagRequest 
     */
    updateFlag(id: string, updateFlagRequest?: UpdateFlagRequest, extraHttpRequestParams?: any): Observable<FlagResource>;

    /**
     * Update user flag values
     * 
     * @param flagId 
     * @param userId 
     * @param updateUserFlagValuesRequest 
     */
    updateUserFlagValues(flagId: string, userId: string, updateUserFlagValuesRequest?: UpdateUserFlagValuesRequest, extraHttpRequestParams?: any): Observable<FlagResource>;

}
