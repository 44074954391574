/**
 * Documentation KFP API
 * ## Common Request Headers - Authorization (string `Bearer {AccessToken}`, if signed-in) - After the client has successfully obtained `AccessToken` via Login, it\'s sent in every request as a bearer token.
 *
 * The version of the OpenAPI document: 1
 * Contact: novak@techcrowd.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CreateCalculationRequest { 
    /**
     * test
     */
    calculationType: string;
    /**
     * test
     */
    inputData?: Array<string>;
    /**
     * test
     */
    clientName?: string;
    /**
     * test
     */
    clientSurname?: string;
    /**
     * test
     */
    description?: string;
    /**
     * test
     */
    draft?: boolean;
    /**
     * test
     */
    currency?: string;
}

