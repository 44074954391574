import {Injectable} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {HttpClient} from '@angular/common/http';
import {Observable, of, switchMap} from 'rxjs';
import {ApexAxisChartSeries} from 'ng-apexcharts';
import {CreateFinancialRequest, FinMortgageInvestmentService} from 'kfp';
import {MortgageParametersService} from './mortgage-parameters.service';

export type SeriesLabel = {
    series: ApexAxisChartSeries;
    labels: string[];
    min?: number;
    max?: number;
    data?: any;
    colors?: string[];
};

@Injectable({
    providedIn: 'root',
})
export class MortgageService {
    constructor(
        public translateService: TranslateService,
        private _httpClient: HttpClient,
        private _mortgagesDsService: FinMortgageInvestmentService,
        private _mortgageParameterService: MortgageParametersService
    ) {
    }

    dataSummary(dataRequest: any): Observable<any> {
        return this._mortgagesDsService.getMultipleFinancialData(dataRequest);
    }

    dataMortgageChart(dataRequest: CreateFinancialRequest): Observable<SeriesLabel[]> {
        return this._mortgagesDsService.getMultipleFinancialData(dataRequest).pipe(
            switchMap((data: any[]) => {

                const oldResult = this._mortgageParameterService.getResult();
                const params = this._mortgageParameterService.getParameters();

                const resultArray: any[] = [];
                data.forEach((item: any, index: number) => {

                    // Extract results from mortgage and investment
                    const mortgageResult = item?.data?.mortgage?.result;
                    const investResult = item?.data?.investment?.result;

                    // Update result with new values if they exist, otherwise keep the old value
                    const updatedMortgageResult = mortgageResult !== undefined ? mortgageResult : oldResult[index]?.mortgageResult;
                    const updatedInvestResult = investResult !== undefined ? investResult : oldResult[index]?.investResult;

                    // Only push results to the result array if they are not both undefined
                    if (updatedMortgageResult !== undefined || updatedInvestResult !== undefined) {
                        resultArray.push({mortgageResult: updatedMortgageResult, investResult: updatedInvestResult});
                    }
                });

                this._mortgageParameterService.setResults(resultArray);

                const seriesLabels: SeriesLabel[] = [];

                // Iterate over each item to construct seriesLabels
                data.forEach((item, index) => {

                    const activeTab = params.items[index].data;

                    const mortgageRecords = item.data.mortgage.records;
                    const investmentRecords = item.data.investment.records;

                    // Determine the maximum series length
                    let maxSeriesLength = 0;
                    if (activeTab.mortgage.active) {
                        maxSeriesLength = Math.max(maxSeriesLength, mortgageRecords.length);
                    }
                    if (activeTab.investment.active) {
                        maxSeriesLength = Math.max(maxSeriesLength, investmentRecords.length);
                    }

                    // Pad each series within this item to match the maximum length
                    const paddedMortgageData = activeTab.mortgage.active ? this.generatePaddedData(mortgageRecords, maxSeriesLength) : [];
                    const paddedInvestData = activeTab.investment.active ? this.generatePaddedData(investmentRecords, maxSeriesLength) : [];


                    const mortgageEvents = activeTab.mortgage.active ? this.extractEventList(paddedMortgageData, 0) : [];
                    const investEvents = activeTab.investment.active ? this.extractEventList(paddedInvestData, 1) : [];

                    const allEvents = [...mortgageEvents, ...investEvents];

                    const mortgageSeries = activeTab.mortgage.active ? {
                        name: 'Hypotéka',
                        type: 'area',
                        seriesIndex: 0,
                        data: paddedMortgageData,
                        color: "#265DEB",
                    } : null;

                    const investmentSeries = activeTab.investment.active ? {
                        name: 'Investice',
                        type: 'area',
                        seriesIndex: 1,
                        data: paddedInvestData,
                        color: "#80E220",
                    } : null;

                    let longestRecords = [];
                    if (activeTab.mortgage.active && activeTab.investment.active) {
                        longestRecords = investmentRecords.length > mortgageRecords.length ? investmentRecords : mortgageRecords;
                    } else if (activeTab.mortgage.active) {
                        longestRecords = mortgageRecords;
                    } else if (activeTab.investment.active) {
                        longestRecords = investmentRecords;
                    }

                    seriesLabels.push({
                        series: [activeTab.mortgage.active ? mortgageSeries : null, activeTab.investment.active ? investmentSeries : null].filter(series => series !== null),
                        labels: longestRecords.map(rec => rec.monthIndex.toString()),
                        allEvents: allEvents,
                        min: Math.min(
                            ...mortgageRecords.filter(rec => activeTab.mortgage.active).map(rec => rec.investmentValue),
                            ...investmentRecords.filter(rec => activeTab.investment.active).map(rec => rec.investmentValue)
                        ),
                        max: Math.max(
                            ...mortgageRecords.filter(rec => activeTab.mortgage.active).map(rec => rec.investmentValue),
                            ...investmentRecords.filter(rec => activeTab.investment.active).map(rec => rec.investmentValue)
                        ),
                        data: item
                    } as any);

                });

                return of(seriesLabels);

            })
        );
    }

    extractEventList(paddedData: any[], seriesIndex: number) {
        const allEvents = [];
        const seenXValues = new Set();

        for (const entry of paddedData) {
            const [monthIndex, investmentValue, event] = entry;
            if (!seenXValues.has(monthIndex) && event !== null) {
                seenXValues.add(monthIndex);
                if (Array.isArray(event)) {
                    for (const singleEvent of event) {
                        allEvents.push({ seriesIndex: seriesIndex, x: monthIndex, y: investmentValue, event: singleEvent });
                    }
                } else {
                    allEvents.push({ seriesIndex: seriesIndex, x: monthIndex, y: investmentValue, event });
                }
            }
        }

        return allEvents;
    }


    generatePaddedData(records: any, maxSeriesLength: number) {
        const data = records.map((rec: any) => [rec.monthIndex, rec.investmentValue, rec.events, rec.interestRate, rec.investmentValue, rec.monthlyPayment]);
        const lastValidEntry = data[data.length - 1]; // Get the last valid entry
        const paddedData = [...data]; // Create a copy of original data
        const eventMap = new Map(); // Map to track the first occurrence of events for each monthIndex

        // Generate the padded data dynamically
        for (let i = data.length; i < maxSeriesLength; i++) {
            const nextMonthIndex = lastValidEntry[0] + (i - data.length + 1);
            paddedData.push([nextMonthIndex, null, null]);
        }

        // Find the first occurrence of each event for each monthIndex and update the eventMap
        for (const entry of paddedData) {
            const [monthIndex, , event] = entry;
            if (event !== null && !eventMap.has(monthIndex)) {
                if (Array.isArray(event)) {
                    eventMap.set(monthIndex, event);
                } else {
                    eventMap.set(monthIndex, [event]);
                }
            } else if (event !== null && eventMap.has(monthIndex)) {
                const events = eventMap.get(monthIndex);
                if (Array.isArray(event)) {
                    for (const singleEvent of event) {
                        if (!events.includes(singleEvent)) {
                            events.push(singleEvent);
                        }
                    }
                    eventMap.set(monthIndex, events);
                } else {
                    if (!events.includes(event)) {
                        events.push(event);
                        eventMap.set(monthIndex, events);
                    }
                }
            }
        }


        // Update paddedData with the events based on eventMap
        for (const [monthIndex, events] of eventMap) {
            const indexesToUpdate = paddedData.reduce((acc: number[], entry, index) => {
                if (entry[0] === monthIndex && entry[2] === null) {
                    acc.push(index);
                }
                return acc;
            }, []);

            for (const index of indexesToUpdate) {
                paddedData[index][2] = events;
            }
        }

        return paddedData;
    }


}
