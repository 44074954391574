/**
 * Documentation KFP API
 * ## Common Request Headers - Authorization (string `Bearer {AccessToken}`, if signed-in) - After the client has successfully obtained `AccessToken` via Login, it\'s sent in every request as a bearer token.
 *
 * The version of the OpenAPI document: 1
 * Contact: novak@techcrowd.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface CouponResource { 
    id: string;
    object?: string;
    amount_off?: string;
    created?: number;
    currency?: string;
    duration?: string;
    duration_in_months?: number;
    livemode?: boolean;
    max_redemptions?: string;
    metadata?: Array<string>;
    name: string;
    percent_off?: number;
    redeem_by?: string;
    times_redeemed?: number;
    valid?: boolean;
}

