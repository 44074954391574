/**
 * Documentation KFP API
 * ## Common Request Headers - Authorization (string `Bearer {AccessToken}`, if signed-in) - After the client has successfully obtained `AccessToken` via Login, it\'s sent in every request as a bearer token.
 *
 * The version of the OpenAPI document: 1
 * Contact: novak@techcrowd.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { SubscriptionsResource } from './subscriptionsResource';
import { CompanyResource } from './companyResource';
import { RoleResource } from './roleResource';
import { UserLicenceResource } from './userLicenceResource';
import { UserResourceBilling } from './userResourceBilling';
import { UserFlagResource } from './userFlagResource';


export interface UserResource { 
    /**
     * User ID
     */
    id: number;
    /**
     * User firstname
     */
    firstname: string;
    /**
     * User lastname
     */
    lastname: string;
    /**
     * User email
     */
    email: string;
    /**
     * User phone
     */
    phone?: string;
    /**
     * Is user verified?
     */
    verified: boolean;
    /**
     * User avatar
     */
    avatar?: string;
    /**
     * User birth date
     */
    birthDate?: string;
    /**
     * User gender
     */
    gender?: string;
    /**
     * User last login
     */
    lastLogin?: string;
    /**
     * addressStreet
     */
    addressStreet?: string;
    /**
     * addressCountry
     */
    addressCountry?: string;
    /**
     * addressCity
     */
    addressCity?: string;
    /**
     * addressNumber
     */
    addressNumber?: string;
    /**
     * addressFull
     */
    addressFull?: string;
    /**
     * language
     */
    language?: string;
    billing?: UserResourceBilling;
    /**
     * longitude
     */
    longitude?: number;
    /**
     * latitude
     */
    latitude?: number;
    /**
     * addressZip
     */
    addressZip?: string;
    /**
     * idNumber
     */
    idNumber?: string;
    /**
     * vatNumber
     */
    vatNumber?: string;
    /**
     * test
     */
    color?: string;
    /**
     * test
     */
    colorSecondary?: string;
    /**
     * test
     */
    loginCount?: number;
    /**
     * test
     */
    personalClaim?: string;
    /**
     * test
     */
    calculationCount?: number;
    /**
     * test
     */
    position?: string;
    /**
     * test
     */
    currency?: string;
    /**
     * test
     */
    hasLicence?: boolean;
    /**
     * test
     */
    hasBranding?: boolean;
    /**
     * test
     */
    manualBranding?: boolean;
    /**
     * test
     */
    pdfImagePath?: string;
    /**
     * test
     */
    imagePath?: string;
    createdAt?: string;
    updatedAt?: string;
    roles: Array<RoleResource>;
    /**
     * List licences from user
     */
    licences?: Array<UserLicenceResource>;
    trialEndsAt?: string;
    paymentType?: string;
    paymentLastFour?: string;
    paymentExp?: string;
    paymentMethodId?: string;
    company?: CompanyResource;
    subscriptions?: Array<SubscriptionsResource>;
    flags?: Array<UserFlagResource>;
}

