import { formatNumber } from '@angular/common';
import { Injectable, Input } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { ChartOptions } from '../services/finmath-chart.service';
import { SeriesLabel } from '../services/finmath.service';
import { ApexAxisChartSeries } from 'ng-apexcharts';
import { AuthService } from '../../../../auth/auth.service';

@Injectable({
    providedIn: 'root',
})
export class InvestmentChart {
    public chart!: ChartOptions;
    logo?: string;
    @Input() height?: number;

    private dataChart = new BehaviorSubject<ChartOptions | null>(null);
    data$ = this.dataChart.asObservable();

    constructor(private authService: AuthService) {
        this.updateChartHeight();
        this.logo = this.authService.getLogoPath();
        this.dataChart.next({
            series: {} as ApexAxisChartSeries,
            chart: {
                background:
                    'linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(/' + this.logo + ') no-repeat center; background-size: auto 30%;',
                toolbar: {
                    tools: {
                        download: false,
                        selection: true,
                        zoom: true, // icon hidden, styles.scss
                        zoomin: false,
                        zoomout: false,
                        pan: false,
                        reset: true,
                    },
                },
                type: 'line',
                height: this.height,
            },
            tooltip: {
                shared: true,
                intersect: false,
                x: {
                    show: true,
                    formatter: (value: any) => (value - 1).toString(),
                },
                y: {
                    formatter: (value: any) => formatNumber(value, 'cs', '1.0-0') + ' ' + this.authService.currency,
                },
            },
            plotOptions: {
                bar: {
                    borderRadius: 10,
                    barHeight: '60%',
                    distributed: true,
                    horizontal: true,
                    dataLabels: {
                        position: 'top',
                    },
                },
            },
            colors: ['#D5F5B5', '#E5E5EA'],
            stroke: {
                curve: 'straight',
                width: [4, 4],
            },
            fill: {
                type: ['solid', 'gradient'],
                opacity: 0.7,
                colors: ['#D5F5B5', '#E5E5EA'],
                gradient: {
                    shade: 'light',
                    type: 'vertical',
                    shadeIntensity: 0.6,
                    opacityFrom: 1,
                    opacityTo: 0.3,
                    stops: [0, 100],
                },
            },
            xaxis: {
                tooltip: {
                    enabled: false,
                },
                labels: {
                    style: {
                        colors: '#95959a',
                    },
                },
            },
            yaxis: {
                min: 0,
                max: 1000000,
                labels: {
                    minWidth: 40,
                    maxWidth: undefined,
                    style: {
                        colors: '#95959a',
                        fontSize: '12px',
                    },
                    formatter: value => formatNumber(value, 'cs', '1.0-0'),
                },
                tickAmount: 5,
            },

            dataLabels: {
                formatter: (value: any) => formatNumber(value, 'cs', '1.2-2'),
                offsetX: 120,
                style: {
                    fontSize: '16px',
                    colors: ['#002B4F'],
                },
            },
            legend: {
                show: false,
            },
        });
    }

    updateSeries(newValue: SeriesLabel): void {
        const currentObject = this.dataChart.value;
        this.updateChartHeight();
        if (currentObject) {
            let updatedObject = { ...currentObject };
            updatedObject.series = newValue.series;
            updatedObject.chart.height = this.height;
            if (updatedObject.xaxis) {
                updatedObject.xaxis.categories = newValue.labels;
            }

            const yaxis = Object.assign({}, updatedObject.yaxis);
            const min = newValue.min;
            const max = newValue.max;

            // Ensure min and max are defined before using them
            if (min !== undefined && max !== undefined) {
                // Adjust min and max if they are the same or differ by less than 1000
                if (max - min < 100) {
                    const mid = (min + max) / 2;
                    const newMin = mid - 500;
                    const newMax = mid + 500;
                    yaxis.min = newMin;
                    yaxis.max = newMax;
                } else {
                    yaxis.min = min;
                    yaxis.max = max;
                }

                updatedObject = { ...updatedObject, yaxis: yaxis };

                this.logo = this.authService.getLogoPath();
                if (this.logo) {
                    updatedObject.chart.background =
                        'linear-gradient(rgba(255,255,255,0.8), rgba(255,255,255,0.8)), url(/' +
                        this.logo +
                        ') no-repeat center; background-size: auto 30%;';
                }
                this.dataChart.next(updatedObject);
            }
        }
    }

    getChartData() {
        return this.dataChart;
    }

    updateChartHeight(): void {
        if (window.innerWidth > 1425) {
            this.height = (window.innerHeight * 64) / 100;
        } else if (window.innerWidth > 995) {
            this.height = window.innerHeight - 380;
        } else {
            this.height = window.innerHeight - 480;
        }
    }
}
