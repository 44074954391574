/**
 * Documentation KFP API
 * ## Common Request Headers - Authorization (string `Bearer {AccessToken}`, if signed-in) - After the client has successfully obtained `AccessToken` via Login, it\'s sent in every request as a bearer token.
 *
 * The version of the OpenAPI document: 1
 * Contact: novak@techcrowd.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { AssignRoleToUserRequest } from '../model/models';
import { GetRoleForUser200Response } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface RoleServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * Assign role for user
     * 
     * @param assignRoleToUserRequest 
     */
    assignRoleToUser(assignRoleToUserRequest?: AssignRoleToUserRequest, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * Get role for user
     * 
     * @param userId User ID
     */
    getRoleForUser(userId: number, extraHttpRequestParams?: any): Observable<GetRoleForUser200Response>;

}
