/**
 * Documentation KFP API
 * ## Common Request Headers - Authorization (string `Bearer {AccessToken}`, if signed-in) - After the client has successfully obtained `AccessToken` via Login, it\'s sent in every request as a bearer token.
 *
 * The version of the OpenAPI document: 1
 * Contact: novak@techcrowd.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


export interface FinDataRecordDto { 
    /**
     * Test
     */
    monthIndex: number;
    /**
     * Test
     */
    month: string;
    /**
     * Test
     */
    shares: number;
    /**
     * Test
     */
    finMarket: number;
    /**
     * Test
     */
    bonds: number;
    /**
     * Test
     */
    inflation: number;
    /**
     * Test
     */
    deltaShares: number;
    /**
     * Test
     */
    deltaFinMarket: number;
    /**
     * Test
     */
    deltaBonds: number;
    /**
     * Test
     */
    deltaInflation: number;
}

