/**
 * Documentation KFP API
 * ## Common Request Headers - Authorization (string `Bearer {AccessToken}`, if signed-in) - After the client has successfully obtained `AccessToken` via Login, it\'s sent in every request as a bearer token.
 *
 * The version of the OpenAPI document: 1
 * Contact: novak@techcrowd.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { ErrorDto } from '../model/models';
import { Login401Response } from '../model/models';
import { LoginRequest } from '../model/models';
import { LoginResource } from '../model/models';
import { LogoutUser200Response } from '../model/models';
import { RefreshTokenRequest } from '../model/models';
import { RegisterUserRequest } from '../model/models';
import { UserResource } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface AuthorizationServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * Login user from admin
     * 
     * @param loginRequest 
     */
    adminLogin(loginRequest?: LoginRequest, extraHttpRequestParams?: any): Observable<LoginResource>;

    /**
     * Logged user
     * 
     */
    loggedUser(extraHttpRequestParams?: any): Observable<UserResource>;

    /**
     * Login user
     * 
     * @param loginRequest 
     */
    login(loginRequest?: LoginRequest, extraHttpRequestParams?: any): Observable<LoginResource>;

    /**
     * Logout user
     * 
     */
    logoutUser(extraHttpRequestParams?: any): Observable<LogoutUser200Response>;

    /**
     * Refresh token
     * Refresh token for generate new access token
     * @param refreshTokenRequest 
     */
    refreshToken(refreshTokenRequest?: RefreshTokenRequest, extraHttpRequestParams?: any): Observable<LoginResource>;

    /**
     * Register user
     * 
     * @param registerUserRequest 
     */
    registerUser(registerUserRequest?: RegisterUserRequest, extraHttpRequestParams?: any): Observable<UserResource>;

}
