import { APP_INITIALIZER, ErrorHandler, Injectable, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ExtraOptions, NoPreloading, Router, RouterModule } from '@angular/router';
import { FuseConfigModule, FuseMockApiModule, FuseModule } from 'fuseui';
import { appConfig } from './core/config/app.config';

import { MatGoogleMapsAutocompleteModule } from '@angular-material-extensions/google-maps-autocomplete';
import { registerLocaleData } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import localeCs from '@angular/common/locales/cs';
import {TranslateLoader, TranslateModule, TranslateService} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { LayoutModule } from 'kfp';
import { TimeagoCustomFormatter, TimeagoFormatter, TimeagoIntl, TimeagoModule } from 'ngx-timeago';
import { ToastrModule } from 'ngx-toastr';
import { KfpModule } from '../../../../libs/kfp/src/lib/kfp.module';
import { AppComponent } from './app.component';
import { appRoutes } from './app.routing';
import { CoreModule } from './core/core.module';
import { mockApiServices } from './mock-api';
import { NgxStripeModule } from 'ngx-stripe';
import * as Sentry from '@sentry/angular-ivy';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { environment } from '../environments/environment';
import {SplashScreenComponent} from '../app-splash-screen.component';

const routerConfig: ExtraOptions = {
    preloadingStrategy: NoPreloading,
    scrollPositionRestoration: 'enabled',
};

export function HttpLoaderFactory(http: HttpClient) {
    return new TranslateHttpLoader(http, './assets/i18n/', '.json?v=' + Date.now());
}

registerLocaleData(localeCs);

@Injectable()
export class MyIntl extends TimeagoIntl {
    constructor(private translateService: TranslateService) {
        super();

        // Initial translation load
        this.loadTranslations();

        // Update translations whenever the language changes
        this.translateService.onLangChange.subscribe(() => {
            this.loadTranslations();
        });
    }

    // Load translations for timeago
    private loadTranslations() {
        this.translateService.get([
            'TIMEAGO.PREFIX_AGO', 'TIMEAGO.HOUR', 'TIMEAGO.HOURS',
            'TIMEAGO.MINUTE', 'TIMEAGO.MINUTES', 'TIMEAGO.SECOND',
            'TIMEAGO.SECONDS', 'TIMEAGO.MONTH', 'TIMEAGO.MONTHS',
            'TIMEAGO.YEAR', 'TIMEAGO.YEARS', 'TIMEAGO.DAY', 'TIMEAGO.DAYS'
        ]).subscribe((translations) => {
            this.strings = {
                prefixAgo: translations['TIMEAGO.PREFIX_AGO'],
                hour: translations['TIMEAGO.HOUR'],
                hours: translations['TIMEAGO.HOURS'],
                minute: translations['TIMEAGO.MINUTE'],
                minutes: translations['TIMEAGO.MINUTES'],
                second: translations['TIMEAGO.SECOND'],
                seconds: translations['TIMEAGO.SECONDS'],
                month: translations['TIMEAGO.MONTH'],
                months: translations['TIMEAGO.MONTHS'],
                year: translations['TIMEAGO.YEAR'],
                years: translations['TIMEAGO.YEARS'],
                day: translations['TIMEAGO.DAY'],
                days: translations['TIMEAGO.DAYS'],
            };

            // Notify Timeago to update the displayed text
            this.changes.next();
        });
    }
}

@NgModule({
    declarations: [AppComponent, SplashScreenComponent],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        RouterModule.forRoot(appRoutes, routerConfig),

        // Fuse, FuseConfig & FuseMockAPI
        FuseModule,
        KfpModule,
        FuseConfigModule.forRoot(appConfig),
        FuseMockApiModule.forRoot(mockApiServices),
        // Core module of your application
        CoreModule,
        // Layout module of your application
        LayoutModule,

        BrowserAnimationsModule, // required animations module
        ToastrModule.forRoot(), // ToastrModule added
        NgxStripeModule.forRoot(environment.stripeKey),

        // Translate module
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                useFactory: HttpLoaderFactory,
                deps: [HttpClient],
            },
        }),
        MatGoogleMapsAutocompleteModule,
        TimeagoModule.forRoot({
            intl: { provide: TimeagoIntl, useClass: MyIntl },
            formatter: { provide: TimeagoFormatter, useClass: TimeagoCustomFormatter },
        }),
        HttpClientModule, AngularSvgIconModule.forRoot()
    ],
    providers: [
        {
            provide: ErrorHandler,
            useValue: Sentry.createErrorHandler({
                showDialog: false,
            }),
        },
        {
            provide: Sentry.TraceService,
            deps: [Router],
        },
        {
            provide: APP_INITIALIZER,
            useFactory: () => () => {},
            deps: [Sentry.TraceService],
            multi: true,
        },
    ],
    bootstrap: [AppComponent, SplashScreenComponent],
})
export class AppModule {}
