/**
 * Documentation KFP API
 * ## Common Request Headers - Authorization (string `Bearer {AccessToken}`, if signed-in) - After the client has successfully obtained `AccessToken` via Login, it\'s sent in every request as a bearer token.
 *
 * The version of the OpenAPI document: 1
 * Contact: novak@techcrowd.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { CompanyLicenceResource } from '../model/models';
import { CreateCompanyLicenceRequest } from '../model/models';
import { UpdateCompanyLicenceRequest } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface CompanyLicenceServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * Saves new company licence to database
     * 
     * @param createCompanyLicenceRequest 
     */
    createNewCompanyLicence(createCompanyLicenceRequest?: CreateCompanyLicenceRequest, extraHttpRequestParams?: any): Observable<CompanyLicenceResource>;

    /**
     * Deletes company licence with given by ID
     * 
     * @param id 
     */
    deleteCompanyLicence(id: string, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * Updates an existing company licence
     * 
     * @param id 
     * @param updateCompanyLicenceRequest 
     */
    updateCompanyLicence(id: string, updateCompanyLicenceRequest?: UpdateCompanyLicenceRequest, extraHttpRequestParams?: any): Observable<CompanyLicenceResource>;

}
