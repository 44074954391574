/**
 * Documentation KFP API
 * ## Common Request Headers - Authorization (string `Bearer {AccessToken}`, if signed-in) - After the client has successfully obtained `AccessToken` via Login, it\'s sent in every request as a bearer token.
 *
 * The version of the OpenAPI document: 1
 * Contact: novak@techcrowd.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { RealEstateDto } from './realEstateDto';
import { MortgageDto } from './mortgageDto';
import { CostDto } from './costDto';
import { RentalIncomeDto } from './rentalIncomeDto';


export interface CreateRealEstateDataRequest { 
    realEstate: RealEstateDto;
    mortgage: MortgageDto;
    rentalIncome: RentalIncomeDto;
    /**
     * Test
     */
    rateFlaged?: number;
    /**
     * Test
     */
    monthlyPayFlaged?: number;
    /**
     * Test
     */
    dueDateFlaged?: number;
    /**
     * Test
     */
    loanValueFlaged?: number;
    oneTimeCosts?: Array<CostDto>;
    monthlyCosts?: Array<CostDto>;
    /**
     * Test
     */
    discountRate: number;
    /**
     * Test
     */
    targetYear: number;
}

