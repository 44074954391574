import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ActivatedRoute, Router } from '@angular/router';
import { AvailableLangs, TranslocoService } from '@ngneat/transloco';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'apps/fuse/src/environments/environment';
import { FuseMediaWatcherService, FuseNavigationItem, FuseNavigationService, FuseVerticalNavigationComponent } from 'fuseui';
import { UserResource } from 'kfp';
import { Browser } from 'leaflet';
import { UpdateUserRequest } from 'libs/kfp/src/lib/api';
import { ActiveComponent } from 'libs/kfp/src/lib/app-service/active-component';
import { AppService } from 'libs/kfp/src/lib/app-service/kfp.service';
import { CalculationsDialogComponent } from 'libs/kfp/src/lib/calculations/calculations-dialog/calculations-dialog.component';
import { CalculationType } from 'libs/kfp/src/lib/dashboard/project/project.component';
import { ProfileService } from 'libs/kfp/src/lib/profile/profile.service';
import { UserService } from 'libs/kfp/src/lib/users/users.service';
import { KfpStripeService } from 'libs/kfp/src/lib/utils/stripe/kfpstripe.service';
import { ToastrService } from 'ngx-toastr';
import { Subject, distinctUntilChanged, of, switchMap, take, takeUntil } from 'rxjs';
import { NavigationService } from '../../../../../../../../apps/fuse/src/app/core/navigation/navigation.service';
import { Navigation } from '../../../../../../../../apps/fuse/src/app/core/navigation/navigation.types';
import { KfpCalculationService } from '../../../../app-service/calculation.service';
import { AuthService } from '../../../../auth/auth.service';
import { HelpModalService } from '../../../../utils/video-dialog/help-modal.service';
import { MortgagePdfDialogComponent } from '../../../../calculations/mortgage/mortgage-pdf-dialog/mortgage-pdf-dialog.component';
import { FlagsService } from 'libs/kfp/src/lib/utils/flags/flags.service';
import { DownloadDialogComponent } from '../../../../calculations/download-dialog/download-dialog.component';
import { FinmathPdfDialogComponent } from '../../../../calculations/finmath/finmath-pdf-dialog/finmath-pdf-dialog.component';

@UntilDestroy()
@Component({
    selector: 'classy-layout',
    templateUrl: './classy.component.html',
    encapsulation: ViewEncapsulation.None,
    changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ClassyLayoutComponent implements OnInit, OnDestroy {
    isScreenSmall?: boolean;
    navigation?: Navigation;
    navLang?: FuseNavigationItem[];
    loggedUser?: UserResource;
    private _unsubscribeAll: Subject<any> = new Subject<any>();
    isAdmin = false;

    activeComponent!: ActiveComponent | null;
    saveLoading = false;
    downloading = false;
    nameEditing = false;

    activeLicenceIndexes: number[] = [];
    lang: string | null;
    availableLangs!: AvailableLangs;
    flagCodes: any;

    hasUserAnySubsOrLicence$ = this.kfpStripeService.hasUserAnySubsOrLicence();

    activeStandardSubscription$ = this.kfpStripeService.activeStandardSubscription$;
    companyLicence$ = this.kfpStripeService.companyLicence$;
    activeUserLicenceDate$ = this.kfpStripeService.activeUserLicenceDate$;

    activeRentPropertySubscription$ = this.kfpStripeService.activeRentPropertySubscription$;
    activeUserRentPropertyLicenceDate$ = this.kfpStripeService.activeUserRentPropertyLicenceDate$;
    companyRentPropertyLicence$ = this.kfpStripeService.companyRentPropertyLicence$;

    activeMortgageInvestSubscription$ = this.kfpStripeService.activeMortgageInvestSubscription$;
    activeUserMortgageInvestLicenceDate$ = this.kfpStripeService.activeUserMortgageInvestLicenceDate$;
    companyMortgageInvestLicence$ = this.kfpStripeService.companyMortgageInvestLicence$;

    activeHistoricalSubscription$ = this.kfpStripeService.activeHistoricalSubscription$;
    activeUserHistoricalLicenceDate$ = this.kfpStripeService.activeUserHistoricalLicenceDate$;
    companyHistoricalLicence$ = this.kfpStripeService.companyHistoricalLicence$;

    activeProbabilitySubscription$ = this.kfpStripeService.activeProbabilitySubscription$;
    activeUserProbabilityLicenceDate$ = this.kfpStripeService.activeUserProbabilityLicenceDate$;
    companyProbabilityLicence$ = this.kfpStripeService.companyProbabilityLicence$;

    activeFinMathSubscription$ = this.kfpStripeService.activeFinMathSubscription$;
    activeUserFinMathLicenceDate$ = this.kfpStripeService.activeUserFinMathLicenceDate$;
    companyFinMathLicence$ = this.kfpStripeService.companyFinMathLicence$;

    /* ADD PRODUCT GUIDE (step-13)
        activeExampleSubscription$ = this.kfpStripeService.activeExampleSubscription$;
        activeUserExampleLicenceDate$ = this.kfpStripeService.activeUserExampleLicenceDate$;
        companyExampleLicence$ = this.kfpStripeService.companyExampleLicence$;
        exampleEnabled = environment.exampleEnabled;
    */

    constructor(
        private _router: Router,
        private _navigationService: NavigationService,
        private _userService: UserService,
        private _fuseMediaWatcherService: FuseMediaWatcherService,
        private _fuseNavigationService: FuseNavigationService,
        public _authService: AuthService,
        private profileService: ProfileService,
        private _appService: AppService,
        private dialog: MatDialog,
        private _calculationService: KfpCalculationService,
        private _changeDetectorRef: ChangeDetectorRef,
        private kfpStripeService: KfpStripeService,
        private helpModal: HelpModalService,
        private _route: ActivatedRoute,
        private toastr: ToastrService,
        private translate: TranslateService,
        private _translocoService: TranslocoService,
        public readonly flagService: FlagsService
    ) {
        // Show title only when on calc screen is active.
        this._appService.sharedData$.subscribe((data: any) => {
            const calcs = ['historical', 'probability', 'fin_math', 'profit', 'mortgage'];
            if (this._router.url && calcs.some(calc => this._router.url.includes(calc))) {
                this.activeComponent = data;
            } else {
                this.activeComponent = null;
            }
            this._changeDetectorRef.markForCheck();
        });

        this.translate.onLangChange.subscribe((lang) => {
            if (this.navigation) {
                this.lang = lang.lang;
                switch (this.lang) {
                    case 'cz':
                        this.navLang = this.navigation.default;
                        break;
                    case 'en':
                        this.navLang = this.navigation.en;
                        break;
                    case 'sk':
                        this.navLang = this.navigation.sk;
                        break;
                    default:
                        this.navLang = this.navigation.default;
                        break;
                }
            }
        });
    }

    get currentYear(): number {
        return new Date().getFullYear();
    }

    ngOnInit(): void {
        this.availableLangs = this._translocoService.getAvailableLangs();
        this.flagCodes = {
            cz: 'CS',
            en: 'EN',
            sk: 'SK',
        };

        // Subscribe to navigation data
        this._navigationService.navigation$.subscribe((navigation: Navigation) => {
            this.navigation = navigation;
        });

        this._authService.profile().pipe(untilDestroyed(this)).subscribe((user) => {
            this.loggedUser = user;
            if (this.loggedUser?.language) {
                if (user?.language === 'čeština') this.loggedUser.language = 'cz';
                this.lang = this.loggedUser.language;
                this.switchLanguage(this.lang ?? 'cz');
            }
            this._changeDetectorRef.markForCheck();
        })

        // Subscribe to media changes
        this._fuseMediaWatcherService.onMediaChange$.pipe(takeUntil(this._unsubscribeAll)).subscribe(({ matchingAliases }) => {
            // Check if the screen is small
            this.isScreenSmall = !matchingAliases.includes('md');
        });

        this._authService.profile().subscribe((user: any) => {
            this._appService.changePrimaryColor(this._authService.getColorPath());
            this._appService.changePrimary2Color(this._authService.getColor2Path());

            this._changeDetectorRef.markForCheck();

            const roles = user.roles;
            roles.forEach((role: any) => {
                if (role.name === 'role.admin' || role.name === 'role.superadmin') {
                    this.isAdmin = true;
                }
            });
        });
    }

    ngOnDestroy(): void {
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }

    toggleNavigation(name: string): void {

        this._authService.profile().pipe(untilDestroyed(this)).subscribe((user) => {
            this.loggedUser = user;
            this._changeDetectorRef.markForCheck();
        })

        // Get the navigation
        const navigation = this._fuseNavigationService.getComponent<FuseVerticalNavigationComponent>(name);

        if (navigation) {
            // Toggle the opened status
            navigation.toggle();
        }
    }

    getNavigation() {
        if (this.navigation) {
            switch (this.lang) {
                case 'cz':
                    return this.navigation.default;
                case 'en':
                    return this.navigation.en;
                case 'sk':
                    return this.navigation.sk;
                default:
                    return this.navigation.default;
            }
            //In this moment user nav is same as admin nav
            //return this.isAdmin ? this.navigation.default : this.navigation.defaultNotAdmin;
        } else {
            return null;
        }
    }

    videoDialog(): void {
        this.helpModal.openHelp('main');
    }

    async saveData() {
        this.saveLoading = true;

        if (this._calculationService.calculation) {
            switch (this._calculationService.calculation.calculationType) {
                case 'history': {
                    try {
                        await this._calculationService.updateHistoryCalculation().toPromise();
                        this.saveLoading = false;
                        this._changeDetectorRef.markForCheck();
                    } catch (error) {
                        console.error('Error updating history calculation:', error);
                        this.saveLoading = false;
                        this._changeDetectorRef.markForCheck();
                    }
                    break;
                }
                case 'probability': {
                    try {
                        await this._calculationService.updateProbabilityCalculation().toPromise();
                        this.saveLoading = false;
                        this._changeDetectorRef.markForCheck();
                    } catch (error) {
                        console.error('Error updating probability calculation:', error);
                        this.saveLoading = false;
                        this._changeDetectorRef.markForCheck();
                    }
                    break;
                }
                case 'fin_math': {
                    try {
                        await this._calculationService.updateFinMathCalculation()?.toPromise();
                        this.saveLoading = false;
                        this._changeDetectorRef.markForCheck();
                    } catch (error) {
                        console.error('Error updating probability calculation:', error);
                        this.saveLoading = false;
                        this._changeDetectorRef.markForCheck();
                    }
                    break;
                }
                case 'profit': {
                    try {
                        await this._calculationService.updateEstateCalculation().toPromise();
                        this.saveLoading = false;
                        this._changeDetectorRef.markForCheck();
                    } catch (error) {
                        console.error('Error updating probability calculation:', error);
                        this.saveLoading = false;
                        this._changeDetectorRef.markForCheck();
                    }
                    break;
                }
                case 'mortgage': {
                    try {
                        await this._calculationService.updateMortgageCalculation().toPromise();
                        this.saveLoading = false;
                        this._changeDetectorRef.markForCheck();
                    } catch (error) {
                        console.error('Error updating probability calculation:', error);
                        this.saveLoading = false;
                        this._changeDetectorRef.markForCheck();
                    }
                    break;
                }
                default: {
                    console.error('Unknown calculation type:', this._calculationService.calculation.calculationType);
                    break;
                }
            }
        } else {
            // @ts-ignore
            await this._calculationService
                .prepareData()
                .pipe(
                    untilDestroyed(this),
                    distinctUntilChanged(),
                    take(1),
                    switchMap((data: any) => {
                        const dialogRef = this.dialog.open(CalculationsDialogComponent, {
                            data: {
                                calculation: data,
                            },
                        });
                        return of(dialogRef);
                    }),
                    switchMap((dialogRef: any) => dialogRef.afterClosed())
                )
                .subscribe((canceled) => {
                    this.saveLoading = false;
                    if (typeof canceled !== 'boolean') {
                        this._userService.user = null;
                    }
                    this._changeDetectorRef.markForCheck();
                });
        }
    }

    createCalculation(type: CalculationType) {
        window.open(this._router.serializeUrl(this._router.createUrlTree([type])), '_blank');
    }

    async downloadPdf() {
        const id = this._route.snapshot.queryParamMap.get('id');
        if (id) {
            this.downloading = true;

            // Save data before fetching PDFs.
            await this.saveData();

            // Just in case wait for BE to process the new saved data before fetching PDFs.
            await new Promise(resolve => setTimeout(resolve, 500));

            this._changeDetectorRef.markForCheck();

            if (this._appService.getData()?.calculationType === 'mortgage') {
                const confirmDialog = this.dialog.open(MortgagePdfDialogComponent, {
                    data: {},
                });

                const confirmed = await confirmDialog.afterClosed().toPromise();

                if (confirmed?.length > 0) {
                    const dialogRef = this.dialog.open(DownloadDialogComponent, {
                        disableClose: true,
                        data: {},
                    });
                    this._calculationService.downloadPdf(id, true, true, true, confirmed).subscribe(
                        () => {
                            this.downloading = false;
                            dialogRef.close();
                            this._changeDetectorRef.markForCheck();
                        },
                        (error) => {
                            this.downloading = false;
                            dialogRef.close();
                            this._changeDetectorRef.markForCheck();
                        }
                    );
                } else {
                    this.downloading = false;
                    this._changeDetectorRef.markForCheck();
                }
            } else if (this._appService.getData()?.calculationType === 'fin_math') {
                const confirmDialog = this.dialog.open(FinmathPdfDialogComponent, {
                    data: {},
                });

                const confirmed = await confirmDialog.afterClosed().toPromise();

                if (confirmed) {
                    const { credit, investment, rent } = confirmed;
                    if (credit || investment || rent) {
                        const dialogRef = this.dialog.open(DownloadDialogComponent, {
                            disableClose: true,
                            data: {},
                        });
                        this._calculationService.downloadPdf(id, confirmed.credit, confirmed.investment, confirmed.rent).subscribe(
                            () => {
                                this.downloading = false;
                                dialogRef.close();
                                this._changeDetectorRef.markForCheck();
                            },
                            (error) => {
                                this.downloading = false;
                                dialogRef.close();
                                this._changeDetectorRef.markForCheck();
                            }
                        );
                    } else {
                        this.downloading = false;
                        this._changeDetectorRef.markForCheck();
                    }
                }
            } else {
                const dialogRef = this.dialog.open(DownloadDialogComponent, {
                    disableClose: true,
                    data: {},
                });
                this._calculationService.downloadPdf(id, true, true, true).subscribe(
                    () => {
                        this.downloading = false;
                        dialogRef.close();
                        this._changeDetectorRef.markForCheck();
                    },
                    (error) => {
                        this.downloading = false;
                        dialogRef.close();
                        this._changeDetectorRef.markForCheck();
                    }
                );
            }
        } else {
            this.saveLoading = true;
            // @ts-ignore
            this._calculationService
                .prepareData()
                .pipe(
                    untilDestroyed(this),
                    distinctUntilChanged(),
                    take(1),
                    switchMap((data: any) => {
                        const dialogRef = this.dialog.open(CalculationsDialogComponent, {
                            data: {
                                calculation: data,
                            },
                        });
                        return of(dialogRef);
                    }),
                    switchMap((dialogRef: any) => dialogRef.afterClosed())
                )
                .subscribe(async () => {
                    this.saveLoading = false;
                    this._changeDetectorRef.markForCheck();
                    const newId = this._route.snapshot.queryParamMap.get('id');
                    if (newId) {
                        this.downloading = true;
                        this._changeDetectorRef.markForCheck();

                        if (this._appService.getData()?.calculationType === 'mortgage') {
                            const confirmDialog = this.dialog.open(MortgagePdfDialogComponent, {
                                data: {},
                            });

                            const confirmed = await confirmDialog.afterClosed().toPromise();

                            if (confirmed?.length > 0) {
                                const dialogRef = this.dialog.open(DownloadDialogComponent, {
                                    disableClose: true,
                                    data: {},
                                });
                                this._calculationService.downloadPdf(newId, true, true, true, confirmed).subscribe(
                                    () => {
                                        this.downloading = false;
                                        dialogRef.close();
                                        this._changeDetectorRef.markForCheck();
                                    },
                                    (error) => {
                                        this.downloading = false;
                                        dialogRef.close();
                                        this._changeDetectorRef.markForCheck();
                                    }
                                );
                            } else {
                                this.downloading = false;
                                this._changeDetectorRef.markForCheck();
                            }
                        } else if (this._appService.getData()?.calculationType === 'fin_math') {
                            const confirmDialog = this.dialog.open(FinmathPdfDialogComponent, {
                                data: {},
                            });

                            const confirmed = await confirmDialog.afterClosed().toPromise();

                            if (confirmed) {
                                const { credit, investment, rent } = confirmed;
                                if (credit || investment || rent) {
                                    const dialogRef = this.dialog.open(DownloadDialogComponent, {
                                        disableClose: true,
                                        data: {},
                                    });
                                    this._calculationService
                                        .downloadPdf(newId, confirmed.credit, confirmed.investment, confirmed.rent)
                                        .subscribe(
                                            () => {
                                                this.downloading = false;
                                                dialogRef.close();
                                                this._changeDetectorRef.markForCheck();
                                            },
                                            (error) => {
                                                this.downloading = false;
                                                dialogRef.close();
                                                this._changeDetectorRef.markForCheck();
                                            }
                                        );
                                } else {
                                    this.downloading = false;
                                    this._changeDetectorRef.markForCheck();
                                }
                            }
                        } else {
                            const dialogRef = this.dialog.open(DownloadDialogComponent, {
                                disableClose: true,
                                data: {},
                            });
                            this._calculationService.downloadPdf(newId, true, true, true).subscribe(
                                () => {
                                    this.downloading = false;
                                    dialogRef.close();
                                    this._changeDetectorRef.markForCheck();
                                },
                                (error) => {
                                    this.downloading = false;
                                    dialogRef.close();
                                    this._changeDetectorRef.markForCheck();
                                }
                            );
                        }
                    } else {
                        this.downloading = false;
                        this.toastr.error(this.translate.instant('section.horizontal_nav.message.error_download_pdf'));
                        this._changeDetectorRef.markForCheck();
                    }
                });
        }
    }

    switchLanguage(language: string) {
        localStorage.setItem('language', language);
        this.lang = language;
        this.translate.use(language);
        if (this.navigation) {
            switch (this.lang) {
                case 'cz':
                    this.navLang = this.navigation.default;
                    break;
                case 'en':
                    this.navLang = this.navigation.en;
                    break;
                case 'sk':
                    this.navLang = this.navigation.sk;
                    break;
                default:
                    this.navLang = this.navigation.default;
                    break;
            }
        }
        this._changeDetectorRef.markForCheck();
    }

    setLanguage(language: string): void {
        this.switchLanguage(language);
        if (this.loggedUser?.language) {
            this.loggedUser.language = language;
        }
        this.profileService
            .updateAuthUser(String(this.loggedUser?.id), this.loggedUser as UpdateUserRequest)
            .pipe(untilDestroyed(this))
            .subscribe();
    }

    editName() {
        this.nameEditing = true;
    }

    saveName() {
        this._calculationService.calculation.calcName = this.activeComponent?.title;
        this.nameEditing = false;
        this.saveData();
    }

}
