/**
 * Documentation KFP API
 * ## Common Request Headers - Authorization (string `Bearer {AccessToken}`, if signed-in) - After the client has successfully obtained `AccessToken` via Login, it\'s sent in every request as a bearer token.
 *
 * The version of the OpenAPI document: 1
 * Contact: novak@techcrowd.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { CancelSubscriptionRequest } from '../model/models';
import { CheckUserSubscriptionRequest } from '../model/models';
import { CouponResource } from '../model/models';
import { CreatePaymentRequest } from '../model/models';
import { CreateSubscriptionRequest } from '../model/models';
import { CreateSubscriptionSessionsRequest } from '../model/models';
import { ProductResource } from '../model/models';
import { UpdatePaymentMethodRequest } from '../model/models';
import { UpdateSubscriptionRequest } from '../model/models';
import { UserResource } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface StripeServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * Cancel user subscription
     * 
     * @param cancelSubscriptionRequest 
     */
    cancelUserSubscription(cancelSubscriptionRequest?: CancelSubscriptionRequest, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * Check user subscription
     * 
     * @param checkUserSubscriptionRequest 
     */
    checkUserSubscription(checkUserSubscriptionRequest?: CheckUserSubscriptionRequest, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * Create new payment intent
     * 
     * @param createPaymentRequest 
     */
    createNewPaymentIntent(createPaymentRequest?: CreatePaymentRequest, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * Create new setup intent
     * 
     */
    createNewSetupIntent(extraHttpRequestParams?: any): Observable<{}>;

    /**
     * Create new user sessions
     * 
     * @param createSubscriptionSessionsRequest 
     */
    createNewUserSessions(createSubscriptionSessionsRequest?: CreateSubscriptionSessionsRequest, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * Create new user subscription
     * 
     * @param createSubscriptionRequest 
     */
    createNewUserSubscription(createSubscriptionRequest?: CreateSubscriptionRequest, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * Get all list of products
     * 
     */
    getAllListOfProducts(extraHttpRequestParams?: any): Observable<Array<ProductResource>>;

    /**
     * Get coupon by Id
     * 
     * @param id 
     */
    getCouponById(id: string, extraHttpRequestParams?: any): Observable<Array<CouponResource>>;

    /**
     * Update user payment method
     * 
     * @param updatePaymentMethodRequest 
     */
    updateUserPaymentMethod(updatePaymentMethodRequest?: UpdatePaymentMethodRequest, extraHttpRequestParams?: any): Observable<{}>;

    /**
     * Update user subscription
     * 
     * @param updateSubscriptionRequest 
     */
    updateUserSubscription(updateSubscriptionRequest?: UpdateSubscriptionRequest, extraHttpRequestParams?: any): Observable<UserResource>;

}
