/**
 * Documentation KFP API
 * ## Common Request Headers - Authorization (string `Bearer {AccessToken}`, if signed-in) - After the client has successfully obtained `AccessToken` via Login, it\'s sent in every request as a bearer token.
 *
 * The version of the OpenAPI document: 1
 * Contact: novak@techcrowd.cz
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
import { HttpHeaders }                                       from '@angular/common/http';

import { Observable }                                        from 'rxjs';

import { CalculatedRecordContainerDto } from '../model/models';
import { CreateHistoryDataRequest } from '../model/models';
import { HistoricDataDto } from '../model/models';
import { ValorizationDto } from '../model/models';


import { Configuration }                                     from '../configuration';



export interface FinHistoryServiceInterface {
    defaultHeaders: HttpHeaders;
    configuration: Configuration;

    /**
     * Investḿent value monthly
     * 
     * @param createHistoryDataRequest 
     */
    calculateInvestmentValueMonthly(createHistoryDataRequest?: CreateHistoryDataRequest, extraHttpRequestParams?: any): Observable<CalculatedRecordContainerDto>;

    /**
     * Investment value yearly
     * 
     * @param createHistoryDataRequest 
     */
    calculateInvestmentValueYearly(createHistoryDataRequest?: CreateHistoryDataRequest, extraHttpRequestParams?: any): Observable<CalculatedRecordContainerDto>;

    /**
     * Historic data
     * 
     * @param createHistoryDataRequest 
     */
    historicData(createHistoryDataRequest?: CreateHistoryDataRequest, extraHttpRequestParams?: any): Observable<HistoricDataDto>;

    /**
     * Valorization
     * 
     * @param createHistoryDataRequest 
     */
    valorization(createHistoryDataRequest?: CreateHistoryDataRequest, extraHttpRequestParams?: any): Observable<ValorizationDto>;

}
